const commonFeature = require("./components/commonFeature");
const carouselNavigationConfig = {
    paddingTop: 20
};

/**
 * Makes banners height equal
 * @param {jQuery} element - the current layout that is being used
 */
function handleBannersHeight(element) {
    var layouts;

    if (element) {
        layouts = element;
    } else {
        layouts = $(".mobile-2r-1c-pd, .mobile-3r-1c-pd");
    }

    layouts.each(function () {
        var layout = $(this);
        var banners = layout.find(".text-banner-layout-pd");
        var firstHeight = 0;
        var currentHeight = 0;
        var maxHeight = 0;
        banners.each(function () {
            $(this).css("height", "auto");
        });
        banners.each(function (i) {
            if (i == 0) {
                firstHeight = $(this).height();
            } else {
                currentHeight = $(this).height();
            }
            if (currentHeight > firstHeight) {
                maxHeight = currentHeight;
            }
        });

        if (maxHeight === 0) {
            maxHeight = firstHeight;
        }

        banners.each(function () {
            if (!commonFeature.isBreakpoint(">=md")) {
                $(this).css("height", "auto");
            } else {
                $(this).css("height", maxHeight);
            }
        });
    });
}

/**
 * Position mobile navigation of the Tabbed Layout
 */
function positionTabbedMobileNav() {
    var tabbedLayouts = $(".tab-layout-pd");

    tabbedLayouts.each(function () {
        var tabbedLayout = $(this);
        var $visual = tabbedLayout.find("img.toi-image");
        var $navigation = tabbedLayout.find(".tab-layout-pd__nav");
        var visualHeight = 0;

        setTimeout(function () {
            if ($visual.length && !commonFeature.isBreakpoint(">=md")) {
                visualHeight = $visual.outerHeight();
                $navigation.css("top", visualHeight + 32);
            } else {
                $navigation.css("top", 0);
            }
        }, 500);
    });
}

function handleImageResize() {
    var toiImages = [].slice.call(document.querySelectorAll(".tab-layout-pd img.toi-image"));

    const resizeObserver = new ResizeObserver((entries) => {
        entries.forEach(function (entry) {
            if (entry && entry.target) {
                var $image = $(entry.target);
                if ($image && $image.parents(".tab-layout-pd .tab-pane.active").length > 0) {
                    var $tabbedLayout = $image.parents(".tab-layout-pd");
                    var $navigation = $tabbedLayout.find(".tab-layout-pd__nav");
                    var visualHeight = 0;

                    if ($image.length && !commonFeature.isBreakpoint(">=md")) {
                        visualHeight = $image.outerHeight();
                        $navigation.css("top", visualHeight + 32);
                    } else {
                        $navigation.css("top", 0);
                    }
                }
            }
        });
    });

    toiImages.forEach(function (toiImage) {
        resizeObserver.observe(toiImage);
    });
}

/**
 * Positioning of the Banner Heading
 */
function positionBannerHeading() {
    var banners = $(".text-beside-image-pd.tbi-version-d");

    if (!banners.length) {
        return;
    }

    banners.each(function () {
        var $banner = $(this);
        var $figure = $banner.find("figure");
        var $visual = $figure.find("> a");
        var $heading = $figure.find("figcaption > .region:first-of-type");

        setTimeout(function () {
            if ($visual.length && $heading.length && !commonFeature.isBreakpoint(">=md")) {
                $heading.clone().insertBefore($visual);
                var $realHeading = $banner.find("figure > figcaption > .region:first-of-type");
                $realHeading.hide();
            } else {
                $banner.find("figure > .region").remove();
                $heading.show();
            }
        }, 500);
    });
}

/**
 * Positioning of the hero carousel navigation
 */
function positionHeroNav() {
    var carousels = $(".hero-carousel-pd .carousel");

    if (!carousels.length) {
        return;
    }

    var $slide;
    var $visual;
    var $figcaption;
    var $carouselControlPrev;
    var $carouselControlNext;
    var $carouselCounter;
    var visualHeight = 0;
    var figcaptionOffsetTop = 0;
    var figcaptionOffsetLeft = 0;
    var figcaptionWidth = 0;
    var figcaptionHeight = 0;
    var navigationHeight = 0;
    var hideFigcaption = false;


    setTimeout(function () {
        carousels.each(function () {
            var carousel = $(this);
            if (carousel.closest(".hero-carousel-pd").hasClass("hc-version-a")) {
                $slide = carousel.find(".text-beside-image-pd");
                if ($slide.length === 0) {
                    $slide = carousel.find(".hero-layout-pd");
                }
                $visual = $slide.find("img");
                $figcaption = $slide.find("figcaption");
                $carouselControlPrev = carousel.find(".carousel-control-prev");
                $carouselControlNext = carousel.find(".carousel-control-next");
                $carouselCounter = carousel.find(".pd-carousel-counter");

                if ($visual.length && !commonFeature.isBreakpoint(">=md")) {
                    visualHeight = $visual.outerHeight();
                    navigationHeight = $carouselControlNext.outerHeight();
                    var additionalPadding = navigationHeight + carouselNavigationConfig.paddingTop;
                    $carouselControlPrev.css({"top": visualHeight + additionalPadding, "left": "auto"});
                    $carouselControlNext.css({"top": visualHeight + additionalPadding, "left": "auto"});
                    $carouselCounter.css({"top": visualHeight + additionalPadding, "left": "auto"});
                } else if ($visual.length && commonFeature.isBreakpoint(">=md") && !commonFeature.isBreakpoint(">=lg")) {
                    
                    figcaptionOffsetTop = $figcaption.offset().top - $figcaption.parent().offset().top + $figcaption.outerHeight() - 22;
                    figcaptionOffsetLeft = $figcaption.find(".region").offset().left;

                    $carouselControlPrev.css({"top": figcaptionOffsetTop, "left": figcaptionOffsetLeft});
                    $carouselCounter.css({"top": figcaptionOffsetTop, "left": figcaptionOffsetLeft + 44});
                    $carouselControlNext.css({"top": figcaptionOffsetTop, "left": figcaptionOffsetLeft + 78});
                    
                } else if ($figcaption.length > 0) {
                    if (!$figcaption.is(":visible")) {
                        hideFigcaption = true;
                        $figcaption.parents(".carousel-item").addClass("active");
                    }
                    figcaptionOffsetTop = $figcaption.offset().top - $figcaption.parent().offset().top;
                    figcaptionOffsetLeft = $figcaption.offset().left - $figcaption.parent().offset().left;
                    figcaptionWidth = $figcaption.outerWidth();
                    figcaptionHeight = $figcaption.outerHeight();
                    $carouselControlPrev.css({"top": figcaptionOffsetTop + figcaptionHeight - 81, "left": figcaptionOffsetLeft + figcaptionWidth - 258});
                    $carouselControlNext.css({"top": figcaptionOffsetTop + figcaptionHeight - 81, "left": figcaptionOffsetLeft + figcaptionWidth - 180});
                    $carouselCounter.css({"top": figcaptionOffsetTop + figcaptionHeight - 81, "left": figcaptionOffsetLeft + figcaptionWidth - 214});
                    if (hideFigcaption) {
                        $figcaption.parents(".carousel-item").removeClass("active");
                    }
                } else {
                    $carouselControlPrev.css({"top": "50%", "left": "77%"});
                    $carouselControlNext.css({"top": "50%", "left": "83%"});
                    $carouselCounter.css({"top": "50%", "left": "80%"});
                }
            }

            if (carousel.closest(".hero-carousel-pd").hasClass("hc-version-b")) {
                $slide = carousel.find(".text-beside-image-pd");
                $visual = $slide.find("img");
                $carouselControlPrev = carousel.find(".carousel-control-prev");
                $carouselControlNext = carousel.find(".carousel-control-next");
                $carouselCounter = carousel.find(".pd-carousel-counter");

                if ($visual.length && !commonFeature.isBreakpoint(">=md")) {
                    navigationHeight = $carouselControlNext.outerHeight();
                    $carouselControlPrev.css({"left": "0", "right": "auto", "bottom": "0"});
                    $carouselControlNext.css({"left": "78px", "right": "auto", "bottom": "0"});
                    $carouselCounter.css({"left": "46px", "right": "auto", "bottom": "0"});
                } else {
                    if ($slide.hasClass("tbi-imageRight")) { /* eslint-disable-line */
                        $carouselControlPrev.css({"left": "auto", "right": "84px"});
                        $carouselControlNext.css({"left": "auto", "right": "6px"});
                        $carouselCounter.css({"left": "auto", "right": "50px"});
                    } else {
                        $carouselControlPrev.css({"left": "6px", "right": "auto"});
                        $carouselControlNext.css({"left": "84px", "right": "auto"});
                        $carouselCounter.css({"left": "50px", "right": "auto"});
                    }
                }
            }

            if (carousel.closest(".hero-carousel-pd").hasClass("hc-version-c")) {
                $slide = carousel.find(".text-beside-image-pd");
                if ($slide.length === 0) {
                    $slide = carousel.find(".hero-layout-pd");
                }
                $visual = $slide.find("img");
                $figcaption = $slide.find("figcaption");
                $carouselControlPrev = carousel.find(".carousel-control-prev");
                $carouselControlNext = carousel.find(".carousel-control-next");
                $carouselCounter = carousel.find(".pd-carousel-counter");

                if ($visual.length && !commonFeature.isBreakpoint(">=md")) {
                    visualHeight = $visual.outerHeight();
                    $carouselControlPrev.css({"top": "auto", "left": "auto"});
                    $carouselControlNext.css({"top": "auto", "left": "auto"});
                    $carouselCounter.css({"top": "auto", "left": "auto"});
                } else if ($figcaption.length > 0) {
                    if (!$figcaption.is(":visible")) {
                        hideFigcaption = true;
                        $figcaption.parents(".carousel-item").addClass("active");
                    }
                    figcaptionOffsetTop = $figcaption.offset().top - $figcaption.parent().offset().top;
                    figcaptionOffsetLeft = $figcaption.offset().left - $figcaption.parent().offset().left;
                    figcaptionWidth = $figcaption.outerWidth();
                    figcaptionHeight = $figcaption.outerHeight();
                    $carouselControlPrev.css({"top": figcaptionOffsetTop + figcaptionHeight - 74, "left": figcaptionOffsetLeft + figcaptionWidth - 166});
                    $carouselControlNext.css({"top": figcaptionOffsetTop + figcaptionHeight - 74, "left": figcaptionOffsetLeft + figcaptionWidth - 88});
                    $carouselCounter.css({"top": figcaptionOffsetTop + figcaptionHeight - 74, "left": figcaptionOffsetLeft + figcaptionWidth - 122});
                    if (hideFigcaption) {
                        $figcaption.parents(".carousel-item").removeClass("active");
                    }
                } else {
                    $carouselControlPrev.css({"top": "50%", "left": "77%"});
                    $carouselControlNext.css({"top": "50%", "left": "83%"});
                    $carouselCounter.css({"top": "50%", "left": "80%"});
                }
            }
        });
    }, 500);
}

/**
 * Makes slides height equal
 * @param {jQuery} element - the current carousel that is being used
 */
function handleSlidesHeight(element) {
    var carousels;

    if (element) {
        carousels = element;
    } else {
        carousels = $(".hero-carousel-pd .carousel");
    }

    if (carousels && carousels.length) {
        carousels.each(function () {
            var carousel = $(this);
            var slides = carousel.find(".carousel-item");
            var firstHeight = 0;
            var currentHeight = 0;
            var maxHeight = 0;
            slides.each(function () {
                $(this).css("height", "auto");
            });
            setTimeout(function () {
                slides.each(function (i) {
                    if (i == 0) {
                        firstHeight = $(this).height();
                    } else {
                        currentHeight = $(this).height();
                    }
                    if (currentHeight > firstHeight) {
                        maxHeight = currentHeight;
                    } else {
                        maxHeight = firstHeight;
                    }
                });
                slides.each(function () {
                    $(this).css("height", maxHeight);
                });
            }, 500);
        });
    }
}

$(document).ready(function () {
    handleBannersHeight();
    positionTabbedMobileNav();
    positionBannerHeading();
    positionHeroNav();
    handleImageResize();

    $(window).on("load", function () {
        handleSlidesHeight();
    });

    $(window).on("windowresize:width", function () {
        handleBannersHeight();
        handleSlidesHeight();
        positionHeroNav();
    });

    $(window).on("breakpoint:change", function () {
        positionTabbedMobileNav();
        positionBannerHeading();
    });

    $(".nav-tabs").on("click", ".nav-link", function () {
        var $carouselWrapper = $(this).closest(".tab-carousel-pd");
        var $activeCarousel = $carouselWrapper.find(".carousel");
        if ($carouselWrapper.length) {
            setTimeout(function () {
                handleSlidesHeight($activeCarousel);
            });
        }
    });
});
